<template>
	<div
		class="kuai_wz_pc padding_120 el-step-pc flex justify_content_sb"
		style="padding-bottom: 20px"
	>
		<div class="MainLeft MainLeft_850 MainLeft_right_30">
			<div v-if="informationDetails.content != null" class="">
				<div class="">
					<div
						class="wz_detail_pc_title"
						:prohibitTran="informationDetails.prohibit_tran"
					>
						{{
							localLanguage == 2
								? informationDetails.title
									? informationDetails.title
									: informationDetails.title
								: informationDetails.title
						}}
					</div>
					<div class="wz_detail_pc_info flex align_items_c justify_content_sb">
						<div class="flex align_items_c wz_detail_pc_info_left">
							<div
								v-if="
									informationDetails.author != null &&
									informationDetails.author.avatar_img != null
								"
							>
								<img
									:src="informationDetails.author.avatar_img"
									alt=""
									class="wz_detail_pc_content_img"
								/>
							</div>
							<div
								v-if="
									informationDetails.author != null &&
									informationDetails.author.nickname != null
								"
								>{{ informationDetails.author.nickname }}
							</div>
							<div>·</div>
							<div v-if="informationDetails.release_time != null">
								{{ informationDetails.release_time }}
							</div>
							<div v-if="informationDetails.source_type > 1">·</div>
							<div>{{
								informationDetails.source_type == 2 ||
								informationDetails.source_type == 10
									? "律动BlockBeats"
									: informationDetails.source_type == 3
									? "Odaily星球日报"
									: informationDetails.source_type == 4
									? "PANews"
									: informationDetails.source_type == 6
									? "Followin"
									: informationDetails.source_type == 7
									? "吴说区块链"
									: informationDetails.source_type == 11
									? "深潮TechFlow"
									: ""
							}}</div>
						</div>
						<div class="flex align_items_c wz_detail_pc_info_right">
							<div
								class="flex align_items_c justify_content_c fx pointer wz_detail_pc_zan"
								@click="collectionInformation()"
							>
								<img
									class="wz_detail_pc_zan"
									src="https://res.metaera.hk/resources/assets/images/kxwz/kxwz_zan_2.png"
									alt=""
									v-if="this.informationDetails.is_collection == 0"
								/>
								<img
									class="wz_detail_pc_zan"
									src="https://res.metaera.hk/resources/assets/images/kxwz/kxwz_zan1_2.png"
									alt=""
									v-if="this.informationDetails.is_collection == 1"
								/>
							</div>
							<div class="wz_detail_line"> </div>
							<div
								class="flex align_items_c justify_content_c fx pointer"
								@click="copyText(informationDetails.id)"
								@mouseenter="showLayer"
								@mouseleave="hideLayer"
							>
								<img
									src="https://res.metaera.hk/resources/assets/images/aboutus/kxwz_wechat_w.png"
									alt=""
								/>
							</div>

							<div v-if="isHover" class="wz_detail_pc_content_wechat_code">
								<div class="wz_detail_pc_content_wechat_code_img">
									<canvas ref="canvas"></canvas>
								</div>
								<div class="wz_detail_pc_content_wechat_code_title"
									>微信扫码</div
								>
							</div>
							<div
								class="flex align_items_c justify_content_c fx pointer"
								@click="twitterShare(informationDetails.id)"
							>
								<img
									src="https://res.metaera.hk/resources/assets/images/aboutus/tui_w.png"
									alt=""
								/>
							</div>
							<div
								class="flex align_items_c justify_content_c fx pointer"
								@click="copyText(informationDetails.id)"
							>
								<img
									src="https://res.metaera.hk/resources/assets/images/aboutus/link_w.png"
									alt=""
								/>
							</div>
						</div>
					</div>
					<div class="wz_detail_pc_content_main">
						<div class="kx_detail_pc_content">
							<div class="wz_detail_pc_content_txt ql-snow">
								<p
									class="ql-editor"
									:prohibitTran="informationDetails.prohibit_tran"
									v-html="
										localLanguage == 2
											? informationDetails.content
												? informationDetails.content
												: informationDetails.content
											: informationDetails.content
									"
								></p>
							</div>
							<div
								class="wz_detail_pc_content_author pointer"
								v-if="informationDetails.type != 2"
								@click="
									$router.push({
										name: '/home_wz',
										params: { id: userInfo.id },
									})
								"
							>
								<div class="wz_detail_pc_content_author_img">
									<img :src="userInfo.avatar_img" alt="" />
								</div>
								<div class="wz_detail_pc_content_author_name">
									<span class="wz_detail_pc_content_author_name1">{{
										userInfo.nickname
									}}</span>
									<span class="wz_detail_pc_content_author_name2">{{
										userInfo.desc
									}}</span>
								</div>
								<div class="arrow-box">
									<img
										src="https://res.metaera.hk/resources/assets/images/aboutus/right_arrow.svg"
									/>
								</div>
							</div>
							<div class="wz_detail_pc_content_tag flex">
								<div
									class="MainLeft_6_item pointer align_items_c"
									v-for="(item, index) in informationDetails.label_info"
									@click="toLabel(item)"
									:key="index"
								>
									{{ item.name }}
								</div>
							</div>
							<div
								class="wz_detail_pc_tip"
								v-if="informationDetails.id != null"
							>
								免责声明：本文内容仅系市场资讯、作者个人或相关方观点，不代表平台的观点和立场，不作为投资理财建议。
							</div>
						</div>
					</div>
				</div>
				<template v-if="informationDetails.type == 2">
					<div class="wz_relate_command">
						<div class="wz_relate_title"> 相关推荐</div>
						<div class="steps kxsteps">
							<el-steps
								direction="vertical"
								:active="1"
								style="padding-top: 40px"
							>
								<el-step
									v-for="(item, index) in recommendLists"
									:key="index"
									class="kx_pc_item"
								>
									<template slot="description">
										<div class="contentBox">
											<router-link
												target="_blank"
												:to="{ name: '/kuaixun_wz', params: { id: item.id } }"
											>
												<div
													class="stepsVal ellipsis_multiple pointer hovercolor"
													:prohibitTran="item.prohibit_tran"
													:style="{ color: item.isRecommend ? '#1545D6' : '' }"
												>
													{{
														localLanguage == 2
															? item.title
																? item.title
																: item.title
															: item.title
													}}
												</div>
											</router-link>
											<div class="kx_line"></div>
										</div>
									</template>
								</el-step>
							</el-steps>
						</div>
					</div>
					<!-- 当前版本不再展示 -->
					<!-- <div class="">
						<div class="wz_relate_title"> 相关文章</div>
						<div class="wz_relate_content">
							<div
								class=""
								v-for="(items, indexs) in recommendLists"
								:key="indexs"
							>
								<wzitem :item="items"></wzitem>
							</div>
						</div>
					</div> -->
				</template>
			</div>
		</div>
		<div class="MainRight MainRight_290">
			<module-part type="1"> </module-part>
			<module-part type="4" :unShowImg="true"> </module-part>
			<module-part type="5" :unShowImg="true"> </module-part>
			<Download></Download>
			<!-- <resou :unshowImg="true"></resou> -->
			<!-- 24H 重要资讯 -->
			<!-- <zixun :partName="'24H 重要资讯'" :selarticleList="hotLists"></zixun> -->
		</div>
	</div>
</template>

<script>
import {
	toUserInfo,
	getInformationDetails,
	collectionInformation,
	cancelCollectionInformation,
} from "@/api/home";
import { homeHotList, getRecommendLists } from "@/api/home";
import start from "@/components/scare.vue";
import { myMixin } from "@/utils/mixin.js";
import { getShareSignaTure } from "@/api/user";
import clipboard from "clipboard";
import { getToken } from "@/utils/auth";
import wzitemsousuo from "./../../components/pc/wzitemsousuo.vue";
import wzitem from "./../../components/pc/wzitem.vue";
// import zixun from "./../../components/zixun.vue";
import modulePart from "../../components/modulePart.vue";
import QRCode from "qrcode";
import { newBus } from "../../components/pc/topHeader2.vue";
import ChineseConv from "chinese-s2t";
import Download from "../../components/pc/download.vue";
export default {
	mixins: [myMixin],
	name: "",
	data() {
		return {
			userInfo: {},
			author_id: "",
			informationDetails: {},
			text: "",
			id: "",
			isHover: false,
			hotLists: [],
			recommendLists: [],
			localLanguage: 1,
			sharetitle:
				this.$route.query.title == null ? "MetaEra" : this.$route.query.title,
			sharedescs:
				"MetaEra是Web3行业的品牌与增长专家，为客户提供创意型解决方案。MetaEra在全球各地区利用全方位优势资源，为您的品牌管理和业务增长提供定制化服务。",
			wxsharelink: location.href,
			shareimg: "https://res.metaera.hk/resources/logo_share.png",
		};
	},
	components: {
		start,
		wzitemsousuo,
		wzitem,
		// zixun,
		modulePart,
		Download,
	},
	created() {
		this.id = this.$route.params.id;
		this.getInformationDetails(this.id);
		this.$router.beforeEach((to, from, next) => {
			if (to.name == "/kuaixun_wz" && this.id != to.query.id) {
				this.id = to.query.id;
				this.informationDetails = {};
				this.getInformationDetails(this.id);
			}
			next();
		});

		// 在 Vue.js 中监听路由变化
		this.$router.afterEach((to, from) => {
			// 更新页面的 <meta> 标签
			this.sharetitle = to.query.title;
			document
				.querySelector('meta[property="og:title"]')
				.setAttribute(
					"content",
					to.query.title == null ? "MetaEra" : to.query.title
				);
			document
				.querySelector('meta[property="og:description"]')
				.setAttribute(
					"content",
					to.query.title == null ? "MetaEra" : to.query.title
				);

			// 触发 Twitter Cards 更新
			if (typeof twttr !== "undefined") {
				twttr.widgets.load();
			}
		});
	},
	mounted() {
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	metaInfo() {
		let descStr = "";
		if (this.informationDetails && this.informationDetails.content) {
			descStr = this.informationDetails.illustrate
				? this.informationDetails.illustrate
				: this.informationDetails.content
						.replace(/<[^>]*>/g, "")
						.slice(0, 2000);
		}

		return {
			title: this.informationDetails.title,
			meta: [
				{
					name: "keywords",
					content:
						"Web3.0,DeFi,MetaEra,blockchain,DePIN,MetaEra,区块链媒体,BTC,比特币,blockchain Media,以太坊,Web3快讯,香港ETF,币圈,Web3资讯,区块链服务,Bitcoin News,区块链是什么,Ethereum News,区块链应用,Crypto News,区块链学习,区块链钱包,区块链活动,GameFi,区块链专栏,meme,区块链游戏,区块链投资,web3钱包,区块链赚钱,公链,链游,区块链投研,USDT,区块链知识,区块链入门",
				},
				{ name: "description", content: descStr },
				{
					property: "og:description",
					content: descStr,
				},
				{
					property: "og:type",
					content: "article",
				},
				{
					property: "og:site_name",
					content: this.informationDetails.title,
				},
				{ property: "og:title", content: this.informationDetails.title },
				{
					property: "twitter:description",
					content: descStr,
				},
				{
					property: "og:image",
					content:
						this.informationDetails.pic != null &&
						this.informationDetails.pic.lenght > 0
							? this.informationDetails.pic
							: "https://res.metaera.hk/resources/logo_share.png",
				},
				{
					property: "twitter:image",
					content:
						this.informationDetails.pic != null &&
						this.informationDetails.pic.lenght > 0
							? this.informationDetails.pic
							: "https://res.metaera.hk/resources/logo_share.png",
				},
				{ property: "twitter:title", content: this.informationDetails.title },
			],
		};
	},
	methods: {
		toLabel(items) {
			this.$router.push({
				name: "/gw_dex",
				params: { id: items.id },
			});
		},
		gettoUserInfo() {
			toUserInfo({ id: this.author_id })
				.then((res) => {
					if (res.code == 200) {
						this.userInfo = res.data;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		updateMetaTags() {
			document.title = this.informationDetails.title;

			const metaKeywords = document.querySelector('meta[name="keywords"]');
			if (metaKeywords) {
				metaKeywords.content =
					"Web3.0,DeFi,MetaEra,blockchain,DePIN,MetaEra,区块链媒体,BTC,比特币,blockchain Media,以太坊,Web3快讯,香港ETF,币圈,Web3资讯,区块链服务,Bitcoin News,区块链是什么,Ethereum News,区块链应用,Crypto News,区块链学习,区块链钱包,区块链活动,GameFi,区块链专栏,meme,区块链游戏,区块链投资,web3钱包,区块链赚钱,公链,链游,区块链投研,USDT,区块链知识,区块链入门";
			} else {
				const newMetaKeywords = document.createElement("meta");
				newMetaKeywords.setAttribute("name", "keywords");
				newMetaKeywords.setAttribute(
					"content",
					"Web3.0,DeFi,MetaEra,blockchain,DePIN,MetaEra,区块链媒体,BTC,比特币,blockchain Media,以太坊,Web3快讯,香港ETF,币圈,Web3资讯,区块链服务,Bitcoin News,区块链是什么,Ethereum News,区块链应用,Crypto News,区块链学习,区块链钱包,区块链活动,GameFi,区块链专栏,meme,区块链游戏,区块链投资,web3钱包,区块链赚钱,公链,链游,区块链投研,USDT,区块链知识,区块链入门"
				);
				document.head.appendChild(newMetaKeywords);
			}

			const metaDescription = document.querySelector(
				'meta[name="description"]'
			);
			const descStr = this.informationDetails.illustrate
				? this.informationDetails.illustrate
				: this.informationDetails.content
						.replace(/<[^>]*>/g, "")
						.slice(0, 2000);
			if (metaDescription) {
				metaDescription.content = descStr;
			} else {
				const newMetaDescription = document.createElement("meta");
				newMetaDescription.setAttribute("name", "description");
				newMetaDescription.setAttribute("content", descStr);
				document.head.appendChild(newMetaDescription);
			}

			const ogDescription = document.querySelector(
				'meta[property="og:description"]'
			);
			if (ogDescription) {
				ogDescription.content = descStr;
			} else {
				const newOgDescription = document.createElement("meta");
				newOgDescription.setAttribute("property", "og:description");
				newOgDescription.setAttribute("content", descStr);
				document.head.appendChild(newOgDescription);
			}

			const ogType = document.querySelector('meta[property="og:type"]');
			if (ogType) {
				ogType.content = "article";
			} else {
				const newOgType = document.createElement("meta");
				newOgType.setAttribute("property", "og:type");
				newOgType.setAttribute("content", "article");
				document.head.appendChild(newOgType);
			}

			const ogSiteName = document.querySelector(
				'meta[property="og:site_name"]'
			);
			if (ogSiteName) {
				ogSiteName.content = this.informationDetails.title;
			} else {
				const newOgSiteName = document.createElement("meta");
				newOgSiteName.setAttribute("property", "og:site_name");
				newOgSiteName.setAttribute("content", "MetaEra");
				document.head.appendChild(newOgSiteName);
			}

			const ogTitle = document.querySelector('meta[property="og:title"]');
			if (ogTitle) {
				ogTitle.content = this.informationDetails.title;
			} else {
				const newOgTitle = document.createElement("meta");
				newOgTitle.setAttribute("property", "og:title");
				newOgTitle.setAttribute("content", this.informationDetails.title);
				document.head.appendChild(newOgTitle);
			}

			const ogImage = document.querySelector('meta[property="og:image"]');
			if (ogImage) {
				ogImage.content = this.informationDetails.pic
					? this.informationDetails.pic
					: "https://res.metaera.hk/resources/logo_share.png";
			} else {
				const newOgImage = document.createElement("meta");
				newOgImage.setAttribute("property", "og:image");
				newOgImage.setAttribute(
					"content",
					this.informationDetails.pic
						? this.informationDetails.pic
						: "https://res.metaera.hk/resources/logo_share.png"
				);
				document.head.appendChild(newOgImage);
			}

			const twitterDescription = document.querySelector(
				'meta[name="twitter:description"]'
			);
			if (twitterDescription) {
				twitterDescription.content = descStr;
			} else {
				const newTwitterDescription = document.createElement("meta");
				newTwitterDescription.setAttribute("name", "twitter:description");
				newTwitterDescription.setAttribute("content", descStr);
				document.head.appendChild(newTwitterDescription);
			}

			const twitterImage = document.querySelector('meta[name="twitter:image"]');
			if (twitterImage) {
				twitterImage.content = this.informationDetails.pic
					? this.informationDetails.pic
					: "https://res.metaera.hk/resources/logo_share.png";
			} else {
				const newTwitterImage = document.createElement("meta");
				newTwitterImage.setAttribute("name", "twitter:image");
				newTwitterImage.setAttribute(
					"content",
					this.informationDetails.pic
						? this.informationDetails.pic
						: "https://res.metaera.hk/resources/logo_share.png"
				);
				document.head.appendChild(newTwitterImage);
			}

			const twitterTitle = document.querySelector('meta[name="twitter:title"]');
			if (twitterTitle) {
				twitterTitle.content = this.informationDetails.title;
			} else {
				const newTwitterTitle = document.createElement("meta");
				newTwitterTitle.setAttribute("name", "twitter:title");
				newTwitterTitle.setAttribute("content", this.informationDetails.title);
				document.head.appendChild(newTwitterTitle);
			}

			const twitterCard = document.querySelector('meta[name="twitter:card"]');
			if (twitterCard) {
				twitterCard.content = "summary";
			} else {
				const newTwitterCard = document.createElement("meta");
				newTwitterCard.setAttribute("name", "twitter:card");
				newTwitterCard.setAttribute("content", "summary");
				document.head.appendChild(newTwitterCard);
			}
		},
		showLayer() {
			this.isHover = true;
			this.generateQRCode();
		},
		hideLayer() {
			this.isHover = false;
		},
		generateQRCode() {
			this.$nextTick(() => {
				const canvas = this.$refs.canvas;
				// const image = this.$refs.image;
				QRCode.toCanvas(
					canvas,
					"https://www.metaera.hk/kuaixun_wz?id=" + this.id,
					(error) => {
						if (error) console.error(error);
						// 将Canvas内容转换为base64编码的图像数据，并设置为img的src属性
						// image.src = canvas.toDataURL();
					}
				);
			});
		},
		twitterShare(id) {
			var sharetitle = ChineseConv.s2t(this.informationDetails.title);
			window.open(
				"http://twitter.com/share?url=" +
					encodeURIComponent("https://web.metaera.hk/article/" + this.id) +
					"&text=" +
					encodeURIComponent(sharetitle) +
					"&display=popup&ref=plugin&src=share_button",
				"twitter",
				"height=500, width=750, top=" +
					100 +
					", left=" +
					100 +
					", toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
			);
		},
		copyText(id) {
			let msg = "https://web.metaera.hk/article/" + id;
			clipboard.copy(msg);
			this.$message({ message: "复制成功", type: "success" });
		},
		showPreView(url) {
			window.open(url, "");
		},
		homeHotList() {
			homeHotList({})
				.then((res) => {
					if (res.code == 200) {
						this.hotLists = res.data.hotLists;
					} else {
					}
				})
				.catch((error) => {});
		},
		getRecommendLists() {
			getRecommendLists({ page: 1, size: 10 })
				.then((res) => {
					if (res.code == 200) {
						this.recommendLists = res.data.list;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		collectionInformation() {
			const hasToken = getToken();
			if (hasToken) {
				if (this.informationDetails.is_collection == 0) {
					collectionInformation({ information_id: this.$route.params.id })
						.then((res) => {
							if (res.code == 200) {
								this.iscollect = true;
								this.getInformationDetails(this.id);
							}
						})
						.catch((error) => {
							// this.$message.error(error.message);
						});
				} else {
					cancelCollectionInformation({ information_id: this.$route.params.id })
						.then((res) => {
							if (res.code == 200) {
								this.iscollect = false;
								this.getInformationDetails(this.id);
							}
						})
						.catch((error) => {
							// this.$message.error(error.message);
						});
				}
			} else {
				this.$PromptTip({ dialogVisible: true, showStatus: 1 });
			}
		},

		//微信分享方法
		WxSharMenu() {
			let urlStr = encodeURIComponent(document.location.href.split("#")[0]);
			getShareSignaTure({
				url: urlStr,
			})
				.then((res) => {
					if (res.code == 200) {
						this.appId = res.data.appId;
						this.timestamp = res.data.timesTamp;
						this.nonceStr = res.data.nonceStr;
						this.signature = res.data.signaTure;

						console.log(this.sharedescs);

						wx.config({
							//注意appId,timestamp,nonceStr,signature四个参数，就是把当前url作为参数请求接口，是接口返回生成的
							debug: false, //生产环境需要关闭debug模式
							appId: this.appId, //appId通过微信服务号后台查看
							timestamp: this.timestamp, //生成签名的时间戳
							nonceStr: this.nonceStr, //生成签名的随机字符串
							signature: this.signature, //签名
							jsApiList: [
								"updateAppMessageShareData",
								"updateTimelineShareData",
							], //需要调用的JS接口列表
						});
						//分享参数，标题，描述，链接，图片title,desc,link,imgUrl
						wx.ready(() => {
							wx.updateAppMessageShareData({
								title: this.sharetitle,
								desc: this.sharedescs,
								link: this.wxsharelink,
								imgUrl: this.shareimg,
								success: function () {
									// 设置成功
									//alert("分享朋友圈设置成功")
								},
							});
							wx.updateTimelineShareData({
								title: this.sharetitle,
								desc: this.sharedescs,
								link: this.wxsharelink,
								imgUrl: this.shareimg,
								success: function () {
									// 设置成功
									//alert(desc)
								},
							});
						});
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		getInformationDetails(id) {
			getInformationDetails({ id: id }).then((res) => {
				if (res.code == 200) {
					this.informationDetails = res.data;
					this.updateMetaTags();
					this.informationDetails.content =
						"<p>" +
						this.informationDetails.content.replace(/\n/g, "</p><p>") +
						"</p>";
					this.getRecommendLists();
					this.homeHotList();
					this.author_id = res.data.author_id;
					this.gettoUserInfo();
					this.$pvuv.getEvent(
						"page",
						this.informationDetails.id,
						this.informationDetails.title,
						this.informationDetails.author_id
					);

					this.sharetitle = this.informationDetails.title;
					this.sharedescs =
						this.informationDetails.illustrate == null ||
						this.informationDetails.illustrate == undefined ||
						this.informationDetails.illustrate.lenght == 0
							? this.informationDetails.title
							: this.informationDetails.illustrate;
					this.WxSharMenu();
				} else {
					this.$message.error("当前快讯不存在1，已返回到快讯列表");
					// this.goBack();
				}
			});
		},
	},

	goBack() {
		console.log("123123");

		if (window.history.length > 1) {
			this.$router.go(-1);
		} else {
			this.$router.push("/home_kuaixun");
		}
	},
};
</script>

<style scoped>
.kuai_wz_pc {
	padding-top: 30px;
}
.MainLeft {
	border-right: 1px solid rgba(216, 216, 216, 0.4);
}

.wz_detail_pc_title {
	font-size: clamp(20px, calc(100vw * 40 / 1440), 40px);
	line-height: clamp(20px, calc(100vw * 50 / 1440), 50px);
	color: #fff;
}
.wz_detail_pc_info {
	margin-top: 20px;
	padding-bottom: 30px;
	border-bottom: 1px solid rgba(216, 216, 216, 0.4);
}

.wz_detail_pc_info_left img {
	width: clamp(10px, calc(100vw * 30 / 1440), 30px);
	height: clamp(10px, calc(100vw * 30 / 1440), 30px);
	border-radius: 50%;
	margin-right: calc(100vw * 10 / 1440);
}

.wz_detail_pc_info_left div {
	font-size: clamp(10px, calc(100vw * 14 / 1440), 14px);
	line-height: 1;
	color: #b6b9cc;
}

.wz_detail_pc_info_right img {
	width: clamp(10px, calc(100vw * 30 / 1440), 30px);
	height: clamp(10px, calc(100vw * 30 / 1440), 30px);
	margin-right: clamp(5px, calc(100vw * 20 / 1440), 20px);
	border-radius: 50%;
}
.wz_detail_line {
	width: 1px;
	height: calc(100vw * 20 / 1440);
	background: #ededed;
	margin-left: clamp(10px, calc(100vw * 16 / 1440), 16px);
	margin-right: clamp(10px, calc(100vw * 20 / 1440), 20px);
}
.wz_detail_pc_tip {
	margin-top: 20px;
	font-size: 12px;
	line-height: 20px;
	color: #fff;
	opacity: 0.4;
}
.MainRight {
	/* padding-left: calc(100vw * 30 / 1440); */
	/* width: calc(100vw * 290 / 1440); */
}

.kxwzDate {
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #9a9aa7;
}

.kxpj img {
	width: 15.7px;
	margin-left: 12px;
}

.kxItem_1 {
	font-size: 22px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	margin-top: 20px;
}
.kxItem_2 {
	font-size: 18px;
	line-height: 36px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	color: #323241 !important;
	font-weight: 400;
}
.kxItem_2 p {
	font-size: 18px;
	margin-left: -14px;
	line-height: 36px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	color: #323241 !important;
	font-weight: 400;
}
.kxItem_2 p span,
.kxItem_2 p a {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #333333;
	margin-top: 18px;
}

.kxItem_3 {
	margin-top: 10px;
}

.btnitem {
	width: 95px;
	height: 23px;
	background: #f5f5f7;
	border-radius: 12px;
	margin-top: 7px;
	font-size: 12px;
	color: #a0a0a8;
	display: flex;
	margin-left: 10px;
}

.btnitem img {
	width: 14px;
	/* height: 18px; */
	margin-right: 6px;
}

.btnitemimg2 {
	height: 20px !important;
	width: auto !important;
}
.btnitem div {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #a0a0a8;
}

.kxstepsBox {
	margin-top: 6px;
	margin-bottom: 2px;
	display: flex;
	justify-content: right;
}

.kxstepsBoxLeft {
	width: auto;
}

.kxItem_3 a {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
}

.MainRight_1 {
	width: 100%;
	height: 58px;
	background: rgba(255, 255, 255, 0);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #f7f7f7;
}

.MainRight_1 img {
	width: 28px;
	height: 33px;
	margin-left: 17px;
}

.MainRight_1 div {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #333333;
	margin-left: 12px;
}
.btnitemimg1 {
	height: 12px;
}
.kxItem_3 .btnitembottom {
	margin-left: 0px;
	width: 20vw;
}
.kxItem_3 .btnitembottom div {
	margin-left: 10px;
	font-size: 14px;
}
.MainLeft_b {
	margin-top: 20px;
}
.wz_relate_command {
	margin-top: calc(100vw * 30 / 1440);
}
.wz_detail_pc_content_txt {
	border-bottom: 1px solid rgba(250, 250, 250, 0.1);
}
.wz_relate_title {
	font-size: calc(100vw * 24 / 1440);
	line-height: 1;
	color: #fff;
	font-weight: bold;
}
</style>
<style>
.MainLeft_tuijian {
	display: none;
}
.MainLeft_b {
	display: block;
}
.kxItem_img {
	display: none;
	width: 100%;
	height: auto;
}
.kxItem_img img {
	width: 100%;
	height: auto;
}
.kuai_wz_pc .ql-editor * {
	color: #fff;
}
.kuai_wz_pc .ql-editor span,
.kuai_wz_pc .ql-editor strong {
	color: #fff !important;
}
.kuai_wz_pc .steps .el-step.is-vertical .el-step__head {
	margin-right: calc(100vw * 8 / 1440);
}
.kuai_wz_pc .kxsteps .el-step__main {
	padding-bottom: 32px;
}
.kuai_wz_pc .steps .contentBox {
	font-size: 18px;
	line-height: 24px;
}
.kuai_wz_pc .kxsteps .el-step__description {
	line-height: 24px;
	margin-bottom: 0;
}
.kuai_wz_pc.el-step-pc .steps .el-step__icon {
	top: -5px !important;
}
</style>
